@use "@angular/material" as mat;
@use "./assets/theme/theme.scss" as theme;
@include mat.core();
@include mat.all-component-themes(theme.theme());
@import "./assets/css/bootstrap.min.css";
@import "../node_modules/font-awesome/css/font-awesome.css";
:root {
  --active-color: #{mat.get-theme-color(theme.theme(), primary, 50)};
  --heading: #{mat.get-theme-color(theme.theme(), primary, 800)};
}

body {
  font-family: "Poppins", sans-serif;
  color: var(--color);
  letter-spacing: 1px;
  margin: 0;
}

.table td {
  padding: 0.1rem 0.75rem;
  vertical-align: middle;
}

.main-container {
  background: var(--whitesmoke);
  padding: 1em;
  min-height: calc(100vh - 4rem);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading);
}
svg {
  fill: var(--heading);
}

.box-title {
  color: var(--heading);
}

.active-link {
  background-color: var(--active-color);
  color: #fff;
  border-radius: 4px;
}

ul {
  margin-bottom: 0 !important;
}
a {
  color: #555;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

// SCROLL BAR
/* Define styles for webkit-based browsers */
::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track background color */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar thumb color */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Scrollbar thumb color on hover */
}

/* Define styles for Firefox */
* {
  scrollbar-width: thin; /* Width of the scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
}

// Common Styling
.transform-text {
  text-transform: capitalize;
}
.capitalText {
  text-transform: capitalize;
}
.upload-logo-container {
  background: var(--border);
  /* border: 2px solid var(--border); */
  border-radius: 6px;
  min-width: 165px;
  min-height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  position: relative;
}

.upload-logo {
  max-width: 50px;
  max-height: 60px;
  cursor: pointer;
}
.lab-logo {
  max-width: 160px;
  min-width: 160px;
  min-height: 125px;
  max-height: 125px;
  border: 2px solid var(--theme-menu-color);
  border-radius: 6px;
  margin-top: 1em;
  object-fit: cover;
}

.example-button-row {
  padding: 1%;
}

.custom-div {
  width: 48%;
  display: inline-block;
  margin: 1%;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 6px;
  margin-left: 10px;
}
.search-filter-container {
  display: flex;
  align-items: center;
}

.bg-white {
  background-color: var(--theme-third-color);
}
.filter {
  border: none;
  padding: 7px 15px;
  margin-left: 10px;
  border-radius: 6px;
  background: var(--theme-menu-color);
  cursor: pointer;
}
.filter:hover {
  background: var(--theme-primary-color);
  color: var(--theme-third-color);
}
.common-btn:hover {
  background: var(--fill);
  color: var(--white);
}
.submit-btn {
  background: var(--fill) !important;
  color: #ffffff;
}
.submit-btn:hover {
  background: var(--hoverfill) !important;
}

.admin-heading {
  text-align: center;
  color: var(--fill);
}

.tab {
  background: var(--fill);
  padding: 10px 0px;
  border-radius: 4px;
  color: var(--white);
  width: 250px;
}
.tab:hover {
  background: var(--hoverfill);
}

.edit-btn {
  border: 1px solid var(--border);
  background: white;
  width: 100%;
}
.edit-btn:hover {
  background-color: var(--border);
}
.lab-tabs {
  display: flex;
  justify-content: center;
}
.active-profile-link {
  background: var(--hoverfill);
}
.form-control::placeholder {
  font-size: 12px !important;
}

// LOGO

.upload-note-text {
  color: var(--Grey);
  font-size: 12px;
}

.logo {
  position: relative;
}

.choose-file-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.logo:hover .choose-file-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.choose-file {
  color: white;
  cursor: pointer;
}

.edit-text {
  position: absolute;
  bottom: 62px;
  right: 22px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 4px 8px;
  display: none;
  cursor: pointer;
  border-radius: 4px;
}

.logo:hover .edit-text {
  display: block;
}

.user-image {
  height: 6rem;
  width: 6rem;
  margin-bottom: 2em;
  border-radius: 50%;
  border: 3px solid var(--border);
  object-fit: cover;
}

.custom-row :first-child {
  padding-left: 10px !important;
}
.custom-row :last-child {
  padding-right: 0 !important;
}

/* Style the checkbox container */
.custom-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 30px; /* Adjust the space for the custom checkbox */
  cursor: pointer;
}

// DIALOG

.mat-mdc-dialog-container .mdc-dialog__surface {
  max-width: 100% !important;
  width: 0px !important;
}
.mdc-dialog--open .mdc-dialog__container {
  max-width: unset !important;
  min-width: 100% !important;
}
.modal-outer.generic-creation-modal {
  .animation-layout {
    opacity: 0.2;
  }
}

.action {
  width: 30px;
  border-radius: 4px;
  box-shadow: 0 2px 4px var(--bs-dark-border-subtle);
  cursor: pointer;
}

// SLIDER

.disease-slider {
  padding-top: 1.5em;
}

.disease-slider h4 {
  margin: 0 2.5em;
  text-transform: uppercase;
}

.disease-slider h3 {
  text-align: center;
}

.disease-slider .slick-slide {
  margin: 5px 1.5em;
  border: 1px solid var(--border);
  box-shadow: 0 1px 3px var(--shadow);
  cursor: pointer;
  border-radius: 5px;
  // height: 185px;
}

.slick-slide img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
}

.slick-slide button {
  border: 1px solid var(--fill);
  color: var(--fill);
  background: var(--white);
  padding: 0.5em 1em;
  width: 100%;
  font-weight: 500;
  margin-top: 1em;
  border-radius: 5px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif !important;
}

.slick-slide button:hover {
  color: white;
}

.sub-box {
  padding: 0.5em;
}

// PACKAGE SLIDER
.package {
  padding-top: 0 !important;
}

.package-type {
  display: flex;
  margin: 0 2.8em;
  margin-top: 1em;
}

.package-type button {
  padding: 5px 10px;
  font-size: 12px;
  background: transparent;
  border: 1px solid var(--color);
  border-radius: 5px;
  color: var(--color);
  letter-spacing: 0.5px;
  cursor: pointer;
  margin-right: 2em;
  width: 150px;
}

.package-type button:hover {
  border: 1px solid var(--fill);
}

.package .slick-slide {
  padding: 0.5em;
}

.package .slick-slide h3 {
  text-align: start !important;
  text-transform: uppercase;
  margin-bottom: 10px !important;
  font-weight: 500;
}

.package .slick-slide h4 {
  margin: 0 !important;
  text-transform: capitalize !important;
  color: var(--lightColor) !important;
}

.package .slick-slide .tests {
  margin-bottom: 10px !important;
  color: var(--lightColor) !important;
}

.package .slick-slide .details h5,
p {
  color: var(--lightColor) !important;
}

.package .slick-slide .details p {
  font-size: 11px !important;
}

.package .slick-slide .info {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.close-icon {
  position: relative;
  margin-bottom: 2em;
  cursor: pointer;
}
.close-icon i {
  position: absolute;
  right: 10px;
  top: -19px;
  background: rgba(36, 39, 44, 0.18);
  padding: 5px 6px;
  border-radius: 50%;
  color: #000;
}

// For Dialog Box
.text {
  font-weight: 600;
  margin-bottom: 6px;
}
.text-value {
  color: var(--color);
}
.filters {
  cursor: pointer;
}
// FOR SKELETON LOADING

.skeleton {
  width: 100%;
  height: 16px;
  margin-bottom: 5px;
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgb(213, 213, 213);
  }
  100% {
    background-color: rgb(189, 189, 189);
  }
}

/**
ALL THE GLOBAL MEDIA QUERIES START
**/

// slide dialog component

.animation-layout {
  .modal-outer.generic-creation-modal {
    opacity: 1;
  }
  max-width: 70vw !important;
  width: 70vw !important;
  animation: slide-left ease 1s;
  @media only screen and (max-width: 767px) {
    max-width: 100vw !important;
    width: 100vw !important;
  }
  @keyframes slide-left {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0%;
    }
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}

.animation-layout-second {
  .modal-outer.generic-creation-modal {
    opacity: 1;
  }
  max-width: 50vw !important;
  width: 50vw !important;
  animation: slide-left ease 1s;
  @media only screen and (max-width: 767px) {
    max-width: 50vw !important;
    width: 50vw !important;
  }
  @keyframes slide-left {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0%;
    }
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}

/**
ALL THE GLOBAL MEDIA QUERIES END
**/

.page-loader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f0f0f0;
  /* Background color of the "paper" */
  padding: 10px;
  border: 1px solid #ccc;
  /* Adjust the width as needed */
}

.line {
  width: 100%;
  height: 16px;
  background-color: #fff;
  /* Color of the "text" */
  margin-bottom: 5px;
  /* Spacing between lines */
  animation: typing 1s infinite alternate;
  /* Typewriter animation */
}

@keyframes typing {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

// Global responsive css
.mat-30 {
  width: 31.33%;
  margin: 0 1%;
}
.mat-100 {
  width: 98%;
  margin: 0 1%;
}
.mat-20 {
  width: 22.99%;
  margin: 0 1%;
}
.mat-50 {
  width: 48%;
  margin: 0 1%;
}
.mat-60 {
  width: 64.66%;
  margin: 0 1%;
}
.mat-80 {
  width: 72.99%;
  margin: 0 1%;
}

// Media Query for responsiveness

@media only screen and (max-width: 750px) {
  .mat-20,
  .mat-30,
  .mat-50,
  .mat-80,
  .mat-60 {
    width: 98% !important;
    margin: 0 1% !important;
  }
}
.search-container {
  border: 1px solid black;
  background-color: var(--theme-third-color);
  padding: 6px 10px;
  border-radius: 6px;
  display: flex;
}
.input-search {
  border: none; /* Remove the border */
  outline: none; /* Remove the outline on focus */
  box-shadow: none; /* Remove any box shadow */
  background-color: transparent; /* Make the background transparent */
}
.actions-cell {
  align-items: center;
  justify-self: center;
}
